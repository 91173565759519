import React from 'react'
import { useSiteMetadata } from '../../hooks'

const Copyright = () => {
  const { copyright } = useSiteMetadata()
  return (
    <div className="text-center">
      {copyright} | Built using{' '}
      <a href="https://github.com/dospolov/gatsby-starter-blog-and-cv" target="_blank">
        Marat Dospolov's Gatsby Blog and CV starter
      </a>{' '}
      and hosted on{' '}
      <a href="https://www.netlify.com/" target="_blank">
        Netlify
      </a>
    </div>
  )
}

export default Copyright
